import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Zoom,
} from "@mui/material";
import * as formik from "formik";
import * as Yup from "yup";
import React, { useMemo, useRef, useState } from "react";
import { TransitionProps } from "@mui/material/transitions";

/**
 *
 * @param props
 * @returns [requestJustification, JSX]
 *
 * requestJustification returns promise which
 * resolves when the justification is provided and returns it as result in the promise
 * and rejects when the justification dialog is closed or cancelled and provides the reason as a string.
 */

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom in ref={ref} {...props} />;
});

export function useJustification(props: {
  title?: string;
  subtitle?: string;
  description?: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  additionalData?: {
    key: string;
    initialValue: any;
    validationSchema: Yup.Schema<any>;
  }[];
  additionalContent?: (any: formik.FormikProps<any>) => JSX.Element;
}) {
  const promiseRef = useRef<{
    resolve: (value: any) => void;
    reject: (value: any) => void;
  }>();

  const [dialogJSX, setDialogJSX] = useState<JSX.Element>(<></>);
  const [justification, setJustification] = useState<string>("");
  const [open, setOpen] = useState(false);

  const initialValues = useMemo(() => {
    return {
      justification: "",
      ...props.additionalData?.reduce((acc, curr) => {
        if (curr.initialValue !== null) {
          acc[curr.key] = curr.initialValue;
        }
        return acc;
      }, {}),
    };
  }, [props.additionalData]);

  React.useEffect(() => {
    setDialogJSX(
      <Dialog
        PaperProps={{
          sx: {
            width: 720,
            maxWidth: "95%",
          },
        }}
        open={open}
        keepMounted
        TransitionComponent={Transition}
        onClose={() => {
          setOpen(false);
          promiseRef.current!.reject("User closed the dialog.");
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <formik.Formik
          validateOnMount
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            justification: Yup.string().required("Justification is required."),
            ...props.additionalData?.reduce((acc, curr) => {
              if (curr.validationSchema) {
                acc[curr.key] = curr.validationSchema;
              }
              return acc;
            }, {}),
          })}
          onSubmit={(values) => {
            promiseRef.current!.resolve(values);
            setOpen(false);
          }}
        >
          {(formProps) => {
            const {
              values,
              setValues,
              setFieldValue,
              isValid,
              resetForm,
              submitForm,
              errors,
              getFieldMeta,
              getFieldProps,
              getFieldHelpers,
            } = formProps;
            return (
              <>
                <DialogTitle>{props.title || "Are you sure?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    {props.description}
                  </DialogContentText>
                  <div>
                    <div>
                      <TextField
                        fullWidth
                        autoFocus
                        multiline
                        rows={4}
                        style={{ minHeight: "6rem" }}
                        placeholder={"Enter text here..."}
                        value={values.justification}
                        onChange={(e) => {
                          setFieldValue("justification", e.target.value);
                        }}
                      />

                      {props.additionalContent?.(formProps)}
                      <div></div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!isValid}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {props.submitButtonText ?? "Submit"}
                  </Button>

                  <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    type="button"
                    onClick={() => {
                      setOpen(false);
                      promiseRef.current!.reject(
                        "User canceled justification."
                      );
                    }}
                  >
                    {props.cancelButtonText ?? "Cancel"}
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </formik.Formik>
      </Dialog>
    );
  }, [justification, open]);

  function requestJustification() {
    setOpen(true);
    setJustification("");
    return new Promise<any>((resolve, reject) => {
      promiseRef.current = { resolve, reject };
    });
  }

  return {
    requestJustification,
    JSX: dialogJSX,
  };
}
