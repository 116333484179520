import {
  Add,
  AddShoppingCart,
  Archive,
  BrowseGallery,
  Close,
  Collections,
  ContentCopy,
  Edit,
  FileCopy,
  NavigateNext,
  NotificationAddOutlined,
  PlayArrow,
  Remove,
  Send,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  CardContent,
  CardMedia,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Card,
  Grid,
  CardActions,
  Button,
  ButtonGroup,
  CardHeader,
  TextField,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Container,
  FormLabel,
  Alert,
  AlertTitle,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  CircularProgress,
  Fab,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { Slide } from "modules/Layout/views/landing.view";
import PromoItemRow from "modules/PromoItems/components/PromoItemRow.component";
import ActivityLog from "modules/UI/components/ActivityLog.component";
import { Attachments } from "modules/UI/components/Attachments.component";
import PUIAvatar from "modules/UI/components/Avatar.component";
import Drawer from "modules/UI/components/Drawer.component";
import { UserFullScopeComponent } from "modules/User/components/userScopeSelector/userFullScopeComponent";
import React, { useEffect, useMemo, useRef } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CreateCampaignContainer from "./create.container";
import { MaterialReactTable } from "material-react-table";
import { Doughnut, Line, Pie } from "react-chartjs-2";
import "chart.js/auto";
import { Formik, FormikHelpers, FormikValues } from "formik";
import CampaignMaterialForm from "../components/CampaignMaterialForm.component";
import {
  AuthenticatedImage,
  AuthenticatedImageBackgroundDiv,
} from "modules/UI/components/AuthenticatedImage.component";
import { SubscribersContainer } from "./subscribers.container";
import { useMutation, useQuery } from "@tanstack/react-query";
import CampaignsService from "../service/campaigns.service";
import { toast } from "react-toastify";
import { useActiveUser } from "modules/User/activeUser";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import { createBlobService } from "utils/factories";
import moment from "moment";
import {
  CampaignMaterialType,
  CampaignState,
  CampaignStatus,
  MediaFileDTO,
} from "../service/campaigns.types";
import { set, values } from "ramda";
import { useJustification } from "modules/UI/hooks/useJustification.hook";
import { CampaignMaterialCard } from "../components/CampaignMaterialCard.component";
import PUIUser from "modules/UI/components/User.component";

const data = [
  {
    Country: "Bulgaria",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_1",
    "Quantity (units)": 5000,
    "MOQ met": true,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.8",
    Incoterm: "DDP",
    "Delivery Address": "Bulgaria, Sofia",
    Date: "2021-11-23",
  },
  {
    Country: "Bulgaria",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_2",
    "Quantity (units)": 4000,
    "MOQ met": true,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.8",
    Incoterm: "DDP",
    "Delivery Address": "Bulgaria, Sofia",
    Date: "2021-11-23",
  },
  {
    Country: "Spain",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_1",
    "Quantity (units)": 3000,
    "MOQ met": false,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.5",
    Incoterm: "FOB",
    "Delivery Address": "Spain, Barcelona",
    Date: "2021-11-23",
  },
  {
    Country: "France",
    "Nestle SH": "ivan.ivanov@nestle.com",
    Code: "notebook_2",
    "Quantity (units)": 3000,
    "MOQ met": false,
    "Consolidated FOB sell price": "1.5",
    "Sell Price": "1.5",
    Incoterm: "FOB",
    "Delivery Address": "France, Paris",
    Date: "2021-11-24",
  },
];

export const CampaignDetailsContainer = () => {
  const currentUser = useActiveUser();
  const navigate = useNavigate();
  const [materialQuantities, setMaterialQuantities] = React.useState({});
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [images, setImages] = React.useState<MediaFileDTO[]>([]);
  const { campaignId } = useParams();
  const [tab, setTab] = React.useState(1);
  const theme = useTheme();
  const ref = useRef();
  const [drawerInitialData, setDrawerInitialData] =
    React.useState<CampaignMaterialType>(null);
  const {
    data: { data: materials },
    refetch: refetchMaterials,
  } = useQuery({
    queryKey: ["materials", campaignId],
    queryFn: async () => {
      const response = await CampaignsService.getCampaignMaterials({
        orderWindowId: parseInt(campaignId),
        take: 100,
        skip: 0,
      });

      setMaterialQuantities(
        response.data.reduce((acc, material) => {
          acc[material.id] = material.minimumOrderQuantity;
          return acc;
        }, {})
      );

      return response;
    },

    initialData: {
      data: [],
      take: 100,
      skip: 0,
      total: 0,
    },
  });
  const { data: subscribers, refetch: refetchSubscribers } = useQuery({
    queryKey: ["subscribers", campaignId],
    queryFn: () => CampaignsService.getCampaignSubscribers({ campaignId }),
    initialData: [],
  });
  const { data: activityLog, refetch: refetchActivityLog } = useQuery({
    queryKey: ["activityLog", campaignId],
    queryFn: () => CampaignsService.getCampaignActivityLog({ campaignId }),
    initialData: [],
  });
  const {
    data: details,
    refetch: refetchDetails,
    isLoading: loadingDetails,
  } = useQuery({
    queryKey: ["campaigns/details", campaignId],
    queryFn: async () => {
      const response = await CampaignsService.getCampaign({
        id: campaignId,
      });
      return response;
    },
    initialData: null,
  });

  const {
    data: requests,
    refetch: refetchRequests,
    isLoading: loadingRequests,
  } = useQuery({
    queryKey: ["campaigns/requests", campaignId],
    queryFn: async () => {
      const response = await CampaignsService.getQuantityRequests({
        id: campaignId,
      });
      return response;
    },
    initialData: [],
  });

  const { mutateAsync: publishCampaign, isPending: publishingCampaign } =
    useMutation({
      mutationKey: ["publishCampaign"],
      mutationFn: async () => {
        await CampaignsService.publishCampaign({ campaignId });
      },
      onSuccess: () => {
        toast.success("Campaign published successfully!");
        navigate("/campaigns")
      },
    });

  useEffect(() => {
    if (!details || details?.mediaFiles?.length === 0) return;
    details?.mediaFiles
      ?.filter((file) => file.mimeType.indexOf("image/") > -1)
      .forEach((image) => {
        createBlobService(image.url, "GET")().then((blob) => {
          setImages((prev) => [
            ...prev,
            { ...image, url: URL.createObjectURL(blob) },
          ]);
        });
      });
  }, [details]);

  const {
    mutateAsync: unsubscribeMemberFromCampaign,
    isPending: unsubscribePending,
  } = useMutation({
    mutationKey: ["unsubscribe", campaignId],
    mutationFn: (email: string) =>
      CampaignsService.unsubscribeMemberFromCampaign({
        campaignId: campaignId,
        email,
      }),
    onSuccess: (data, variables, context) => {
      toast.success("Unsubscribed successfully");
      refetchSubscribers();
    },
  });
  const { mutate: subcribeMeToCampaign, isPending: subscribeMePending } =
    useMutation({
      mutationKey: ["subscribeMe", campaignId],
      mutationFn: () => CampaignsService.subcribeMeToCampaign({ campaignId }),
      onSuccess: (data, variables, context) => {
        toast.success("Subscribed successfully");
        refetchSubscribers();
      },
    });

  const {
    mutateAsync: removeCampaignMaterial,
    isPending: removeCampaignMaterialPending,
  } = useMutation({
    mutationKey: ["removeCampaignMaterial"],
    mutationFn: (materialId: number) =>
      CampaignsService.deleteCampaignMaterial({ id: materialId }),
    onSuccess: (data, variables, context) => {
      toast.success("Material removed successfully");
      refetchMaterials();
      refetchDetails();
    },
  });

  const {
    mutateAsync: duplicateCampaignMaterial,
    isPending: duplicateCampaignMaterialPending,
  } = useMutation({
    mutationKey: ["duplicateCampaignMaterial"],
    mutationFn: (materialId: number) =>
      CampaignsService.duplicateCampaignMaterial({ materialId }),
    onSuccess: (data, variables, context) => {
      toast.success("Material duplicated successfully");
      refetchMaterials();
      refetchDetails();
    },
  });

  const { mutateAsync: uploadCampaignMedia } = useMutation<
    any,
    any,
    Parameters<typeof CampaignsService.uploadCampaignMedia>[0]
  >({
    mutationKey: ["uploadCampaignMedia"],
    mutationFn: async (data) => {
      const response = await CampaignsService.uploadCampaignMedia(data);
      return response;
    },
  });

  const { mutateAsync: deleteCampaignMedia } = useMutation<
    any,
    any,
    Parameters<typeof CampaignsService.deleteCampaignMedia>[0]
  >({
    mutationKey: ["deleteCampaignMedia"],
    mutationFn: async ({ fileId }) => {
      await CampaignsService.deleteCampaignMedia({ fileId });
      return fileId;
    },
    onSuccess: () => {
      toast.success("File deleted successfully!");
      refetchDetails();
    },
  });
  const { mutateAsync: deleteQuantityRequest } = useMutation<
    any,
    any,
    Parameters<typeof CampaignsService.deleteQuantityRequest>[0]
  >({
    mutationKey: ["deleteQuantityRequest"],
    mutationFn: async ({ id }) => {
      await CampaignsService.deleteQuantityRequest({ id });
      return id;
    },
    onSuccess: () => {
      toast.success("Request revoked successfully!");
      refetchRequests();
    },
  });

  const { mutateAsync: archiveCampaign, isPending: archivingCampaign } =
    useMutation<
      any,
      any,
      Parameters<typeof CampaignsService.archiveCampaign>[0]
    >({
      mutationKey: ["archiveCampaign"],
      mutationFn: async ({ campaignId }) => {
        await CampaignsService.archiveCampaign({ campaignId });
        return campaignId;
      },
      onSuccess: () => {
        toast.success("Campaign archived successfully!");
        refetchDetails();
      },
    });

  const campaignPercentage = useMemo(() => {
    if (!details || !details.startDate || !details.endDate) return 0;
    return Math.round(
      (moment(details?.endDate).diff(moment(details?.startDate), "days") /
        moment().diff(moment(details?.startDate), "days")) *
        100
    );
  }, [details]);

  return !details || loadingDetails || loadingRequests ? (
    <LinearProgress />
  ) : (
    <>
      <Drawer
        open={!!drawerInitialData}
        onClose={() => setDrawerInitialData(null)}
        title="Product details"
      >
        <CampaignMaterialForm
          initialValues={drawerInitialData}
          onClose={() => {
            setDrawerInitialData(null);
            refetchMaterials();
            refetchDetails();
          }}
        />
      </Drawer>
      <Drawer
        title="Edit Campaign"
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        {createDialogOpen && (
          <CreateCampaignContainer
            onClose={() => {
              setCreateDialogOpen(false);
              refetchMaterials();
              refetchDetails();
            }}
            initialData={details}
          />
        )}
      </Drawer>
      <Box
        sx={{
          m: -3,
          mb: 3,
          p: 3,
          background: theme.palette.background.paper,
          position: "relative",
        }}
      >
        {/* <Slide
          isActive={activeSlide === 0}
          backgroundImage="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
          onNext={() => {
            setActiveSlide(1);
          }}
        />
        <Slide
          isActive={activeSlide === 1}
          backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de70ae11079143.560f10407d139.jpg"
          onPrevious={() => {
            setActiveSlide(0);
          }}
        /> */}
        <Breadcrumbs
          sx={{ mb: 3 }}
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          {[
            <NavLink key="1" color="inherit" to="/campaigns" onClick={() => {}}>
              Campaigns
            </NavLink>,
            <Typography key="3" color="text.primary">
              {details ? details.name : `Campaign #${campaignId}`}
            </Typography>,
          ]}
        </Breadcrumbs>

        <Grid
          container
          spacing={4}
          justifyContent={"space-between"}
          alignContent={"center"}
        >
          <Grid item>
            <img
              alt="logo"
              src="https://link-worldwide.com/wp-content/themes/asl-link/dist/images/link-logo.svg"
              width="150px"
              height="80px"
            />
          </Grid>
          <Grid item flexGrow={1}>
            {details?.name && (
              <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {details?.name}
              </Typography>
            )}
            {details?.startDate && details?.endDate && (
              <Typography variant="h6">
                {moment(details?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(details?.endDate).format("DD/MM/YYYY")}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {!details.isDeleted && (
              <ButtonGroup>
                <Button
                  size="large"
                  variant="outlined"
                  disabled={archivingCampaign}
                  onClick={() => {
                    archiveCampaign({ campaignId });
                  }}
                  startIcon={<Archive />}
                >
                  Arhive
                </Button>

                {(details.state === CampaignState.Draft ||
                  details.status === CampaignStatus.Draft) && (
                  <>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => setCreateDialogOpen(true)}
                      startIcon={<Edit />}
                    >
                      Edit
                    </Button>
                    <Tooltip
                      title={
                        details.publishErrors?.length > 0
                          ? details.publishErrors[0].message
                          : "Publish campaign"
                      }
                    >
                      <div>
                        <Button
                          size="large"
                          variant="contained"
                          disabled={
                            details.publishErrors?.length > 0 ||
                            publishingCampaign
                          }
                          startIcon={<Send />}
                          onClick={() => {
                            publishCampaign();
                          }}
                        >
                          Publish
                        </Button>
                      </div>
                    </Tooltip>
                  </>
                )}
                {!subscribers.find(
                  (subscriber) => subscriber.email === currentUser.email
                ) && (
                  <Tooltip title="Subcribe to this campaign to receive updates">
                    <Button
                      size="large"
                      variant="contained"
                      startIcon={<NotificationAddOutlined />}
                      onClick={() => subcribeMeToCampaign()}
                      disabled={subscribeMePending}
                    >
                      Subscribe
                    </Button>
                  </Tooltip>
                )}
              </ButtonGroup>
            )}
          </Grid>
          <Grid item xs={12} sx={{ pt: 0, mt: -3, mb: -6 }}>
            <LinearProgress variant="determinate" value={campaignPercentage} />
          </Grid>
        </Grid>
      </Box>
      {/* <Box>
        <Container maxWidth="xl">
          <Stepper activeStep={1} alternativeLabel sx={{ my: 6 }}>
            <Step>
              <StepLabel>OW Created</StepLabel>
            </Step>
            <Step>
              <StepLabel
                optional={moment(details?.startDate).format("DD/MM/YYYY")}
              >
                Order Window Opens
              </StepLabel>
            </Step>
            <Step>
              <StepLabel optional="Pending/Reached">
                Minimum Order Quantity
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
                optional={moment(details?.endDate).format("DD/MM/YYYY")}
              >
                Order Window Closed
              </StepLabel>
            </Step>
             <Step>
            <StepLabel optional="final prices quoted">
              Quantities consolidated
            </StepLabel>
          </Step>
          <Step>
            <StepLabel optional="final prices quoted">PO's Issued</StepLabel>
          </Step>
          <Step>
            <StepLabel optional="09-12-2024">Delivery</StepLabel>
          </Step> 
          </Stepper>
        </Container>
      </Box> */}
      <Container maxWidth="xl">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            {/* <CreateCampaignContainer /> */}
            {images.length > 0 && (
              <Paper
                sx={{
                  mb: 10,
                  overflow: "hidden",
                  position: "relative",
                  border: `1px solid ${theme.palette.background.paper}`,
                  "&:after": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    opacity: ".2",
                    background: "linear-gradient(45deg, #f3f3f3, transparent)",
                  },
                }}
                elevation={10}
              >
                <PUIRatioBox xRatio={16} yRatio={9}>
                  {[...images]
                    .sort(function (a, b) {
                      return (
                        new Date(a.uploadedOn).getTime() -
                        new Date(b.uploadedOn).getTime()
                      );
                    })
                    .map((image, index) => {
                      return (
                        <Slide
                          key={image.url}
                          isActive={activeSlide === index}
                          backgroundImage={image.url}
                          onNext={
                            activeSlide === images.length - 1
                              ? null
                              : () => {
                                  setActiveSlide(index + 1);
                                }
                          }
                          onPrevious={
                            activeSlide === 0
                              ? null
                              : () => setActiveSlide(index - 1)
                          }
                        />
                      );
                    })}
                  {/* <Slide
                isActive={activeSlide === 0}
                backgroundImage="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
                onNext={() => {
                  setActiveSlide(1);
                }}
              />
              <Slide
                isActive={activeSlide === 1}
                backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de70ae11079143.560f10407d139.jpg"
                onPrevious={() => {
                  setActiveSlide(0);
                }}
              /> */}
                </PUIRatioBox>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={tab}
                onChange={(_, newValue) => setTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                aria-label="full width tabs example"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab label="Campaign Details" />
                <Tab label="Materials" />
                <Tab label="Attachments" />
                <Tab label="Subscribers" />
                <Tab label="Activity Log" />
                <Tab label="Quantity Consolidation" />
              </Tabs>

              {tab === 0 && (
                <Box padding={2}>
                  <Grid container spacing={10}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h5" sx={{ mb: 4 }}>
                        Campaign Details
                      </Typography>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          Title:
                        </Grid>
                        <Grid item xs={6}>
                          {details.name}
                        </Grid>
                        <Grid item xs={6}>
                          About:
                        </Grid>
                        <Grid item xs={6}>
                          {details.description}
                        </Grid>
                        <Grid item xs={6}>
                          Scope:
                        </Grid>
                        <Grid item xs={6}>
                          {[
                            ...details.zones?.map((z) => z.name),
                            ...details.markets?.map((m) => m.name),
                            ...details.companyCodes?.map((c) => c.name),
                          ].join(", ")}
                        </Grid>

                        <Grid item xs={6}>
                          Start Date:
                        </Grid>
                        <Grid item xs={6}>
                          {moment(details.startDate).format("DD/MM/YYYY")}
                        </Grid>
                        <Grid item xs={6}>
                          End Date:
                        </Grid>
                        <Grid item xs={6}>
                          {moment(details.endDate).format("DD/MM/YYYY")}
                        </Grid>
                        <Grid item xs={6}>
                          Duration:
                        </Grid>
                        <Grid item xs={6}>
                          {moment(details.endDate).diff(
                            moment(details.startDate),
                            "days"
                          )}
                          {" days"}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h5" sx={{ mb: 4 }}>
                        Supplier Details
                      </Typography>
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          Supplier:
                        </Grid>
                        <Grid item xs={6}>
                          [{details.supplierCode}] - {details.supplierName}
                        </Grid>
                        <Grid item xs={6}>
                          Contact Person:
                        </Grid>
                        <Grid item xs={6}>
                          <PUIUser
                            name={details.supplierContact.username}
                            email={details.supplierContact.email}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {tab === 1 && (
                <Box padding={4}>
                  <Grid container spacing={4}>
                    {materials
                      ?.filter((material) => !material.deleted)
                      .map((material) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={material.id}
                          >
                            <CampaignMaterialCard
                              material={material}
                              campaign={details}
                              requests={requests.find(
                                (req) =>
                                  req.orderWindowMaterialId === material.id
                              )}
                              onRemove={(material) => {
                                removeCampaignMaterial(material.id);
                              }}
                              onDuplicate={(material) => {
                                duplicateCampaignMaterial(material.id);
                              }}
                              onEdit={(material) => {
                                setDrawerInitialData(material);
                              }}
                            />
                          </Grid>
                        );
                      })}
                    <Grid item xs={12} display="flex" justifyContent={"center"}>
                      {!details.isDeleted &&
                        details.state === CampaignState.Draft && (
                          <Fab
                            color="primary"
                            variant="extended"
                            onClick={() =>
                              setDrawerInitialData({
                                orderWindowId: parseInt(campaignId),
                                minimumOrderQuantity: 0,
                                // packQuantity: 0,
                                // packUnit: "",
                                itemDescription:
                                  "S390041 - 39 Printed Material and Premiums",
                                shortDescription:
                                  "S390041 - 39 Printed Material and Premiums",
                                purchQuantity: 0,
                                priceRanges: [],
                                mediaFiles: [],
                              })
                            }
                          >
                            Add New Material
                          </Fab>
                        )}
                    </Grid>
                  </Grid>
                </Box>
              )}

              {tab === 2 && (
                <Box padding={2}>
                  <Attachments
                    enableDelete={!details.isDeleted}
                    enableUpload={!details.isDeleted}
                    onClick={(attachment) => {
                      createBlobService(attachment.url, "GET")().then(
                        (blob) => {
                          const url = URL.createObjectURL(blob);
                          const downloadLink = document.createElement("a");
                          downloadLink.href = url;
                          downloadLink.download = attachment.fileName;
                          document.body.appendChild(downloadLink);
                          downloadLink.click();
                          window.URL.revokeObjectURL(url);
                          document.body.removeChild(downloadLink);
                        }
                      );
                    }}
                    onAttach={(file) => {
                      uploadCampaignMedia({
                        campaignId: campaignId,
                        file: file,
                      });
                    }}
                    onDelete={(file) => {
                      deleteCampaignMedia({ fileId: file.id.toString() });
                    }}
                    accept={{
                      "message/rfc822": [".eml"],
                      "application/vnd.ms-outlook": [".msg"],
                      "application/pdf": [".pdf"],
                      "application/msword": [".doc", ".docx"],
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        [".docx"],
                      "application/vnd.ms-excel": [".xls", ".xlsx"],
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        [".xlsx"],
                      "application/vnd.ms-powerpoint": [".ppt", ".pptx"],
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                        [".pptx"],
                      "video/mp4": [".mp4"],
                      "image/svg+xml": [".svg"],
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                    }}
                    attachments={details?.mediaFiles?.map((mediaFile) => ({
                      creationDate: mediaFile.uploadedOn,
                      fileName: mediaFile.originalFileName,
                      id: mediaFile.fileId,
                      uploadedByUserEmail: mediaFile.uploadedBy?.email,
                      url: mediaFile.url,
                    }))}
                  />
                </Box>
              )}
              {tab === 3 && (
                <SubscribersContainer
                  readOnly={unsubscribePending}
                  subscribers={subscribers}
                  onUnsubscribe={(email) =>
                    unsubscribeMemberFromCampaign(email)
                  }
                />
              )}
              {tab === 4 && <ActivityLog entries={activityLog} />}
              {tab === 5 && (
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Card>
                        <CardContent>
                          <Doughnut
                            ref={ref}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: "Quantities by country",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              },
                            }}
                            data={{
                              labels: Object.keys(
                                data.reduce((acc, curr) => {
                                  acc[curr.Country] =
                                    (acc[curr.Country] || 0) +
                                    curr["Quantity (units)"];
                                  return acc;
                                }, {})
                              ),
                              datasets: [
                                {
                                  label: "Quantity (units)",
                                  data: Object.values(
                                    data.reduce((acc, curr) => {
                                      acc[curr.Country] =
                                        (acc[curr.Country] || 0) +
                                        curr["Quantity (units)"];
                                      return acc;
                                    }, {})
                                  ),
                                  backgroundColor: [
                                    theme.palette.primary.main,
                                    theme.palette.secondary.main,
                                    theme.palette.error.main,
                                  ],
                                },
                              ],
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={3}>
                      <Card>
                        <CardContent>
                          <Doughnut
                            ref={ref}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: "Quantities by material",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              },
                            }}
                            data={{
                              labels: Object.keys(
                                data.reduce((acc, curr) => {
                                  acc[curr.Code] =
                                    (acc[curr.Code] || 0) +
                                    curr["Quantity (units)"];
                                  return acc;
                                }, {})
                              ),
                              datasets: [
                                {
                                  label: "Quantity (units)",
                                  data: Object.values(
                                    data.reduce((acc, curr) => {
                                      acc[curr.Code] =
                                        (acc[curr.Code] || 0) +
                                        curr["Quantity (units)"];
                                      return acc;
                                    }, {})
                                  ),
                                  backgroundColor: [
                                    theme.palette.primary.main,
                                    theme.palette.secondary.main,
                                    theme.palette.error.main,
                                  ],
                                },
                              ],
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card>
                        <CardContent>
                          <Line
                            ref={ref}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: "Quantities by date",
                                },
                                legend: {
                                  display: true,
                                  position: "bottom",
                                },
                              },
                            }}
                            data={{
                              labels: Object.keys(
                                data.reduce((acc, curr) => {
                                  acc[curr.Date] =
                                    (acc[curr.Date] || 0) +
                                    curr["Quantity (units)"];
                                  return acc;
                                }, {})
                              ),
                              datasets: [
                                {
                                  label: "Quantity (units)",
                                  data: Object.values(
                                    data.reduce((acc, curr) => {
                                      acc[curr.Date] =
                                        (acc[curr.Date] || 0) +
                                        curr["Quantity (units)"];
                                      return acc;
                                    }, {})
                                  ),
                                  backgroundColor: [
                                    theme.palette.primary.main,
                                    theme.palette.secondary.main,
                                    theme.palette.error.main,
                                  ],
                                },
                              ],
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <MaterialReactTable
                        initialState={{
                          columnPinning: {
                            left: ["mrt-row-expand", "mrt-row-select"],
                            right: ["mrt-row-actions"],
                          },
                        }}
                        muiTablePaperProps={({ table }) => ({
                          style: { background: "white !important" },
                          position: "relative",
                          zIndex: table.getState().isFullScreen
                            ? 1300
                            : undefined,
                        })}
                        data={requests?.reduce((acc, { requests, ...rest }) => {
                          acc.push(
                            ...requests.map((request) => {
                              return {
                                material: rest,
                                ...request,
                              };
                            })
                          );
                          return acc;
                        }, [])}
                        enableGrouping
                        enableRowActions
                        renderRowActionMenuItems={({ closeMenu, row }) => {
                          return [
                            <MenuItem onClick={closeMenu}>Update</MenuItem>,
                            <MenuItem
                              onClick={() => {
                                deleteQuantityRequest({ id: row.original.id });
                                closeMenu();
                              }}
                            >
                              Revoke
                            </MenuItem>,
                          ];
                        }}
                        columns={[
                          { header: "Request #", accessorKey: "id" },
                          {
                            header: "Market",
                            accessorKey: "market.name",
                          },
                          {
                            header: "Quantity",
                            accessorKey: "quantity",
                          },
                          {
                            header: "Requestor",
                            accessorKey: "requestor",
                          },
                          {
                            header: "Quantity (units)",
                            accessorKey: "Quantity (units)",
                          },
                          {
                            header: "MOQ met",
                            accessorKey: "material.moqMet",
                            Cell: ({ row }) => {
                              return row.original.material.moqMet
                                ? "Yes"
                                : "No";
                            },
                          },
                          {
                            header: "Minimum Order Quantity",
                            accessorKey: "material.minimumOrderQuantity",
                          },
                          {
                            header: "Final Price",
                            accessorKey: "material.finalPrice",
                          },
                          {
                            header: "totalSellPrice",
                            accessorKey: "material.totalSellPrice",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CampaignDetailsContainer;
