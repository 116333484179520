import { PromoItemRowSchema } from "modules/PromoItems/services/promoItems.schemas";
import moment from "moment";
import {
  IdNamePairSchema,
  IdTitlePairSchema,
  ShortUserSchema,
} from "service/generic.schemas";
import * as yup from "yup";

export const MediaFileSchema = yup.object().shape({
  fileId: yup.number().nullable().notRequired(),
  url: yup.string().required(),
  mimeType: yup.string().required(),
  originalFileName: yup.string().required(),
  persistedFileNameInAzureStorage: yup.string().required(),
  uploadedOn: yup.string().required(),
  uploadedBy: ShortUserSchema,
});

export const CreateCampaignSchema = yup.object().shape({
  id: yup.number().nullable().notRequired(),
  name: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
  startDate: yup
    .string()
    .datetime("Enter valid date")
    .required("This field is required"),
  endDate: yup
    .string()
    .datetime("Enter valid date")
    .test(
      "dates-test",
      "Campaign end date should be after campaign start date",
      (value, context) => {
        let startDate = moment(context.parent.startDate).toDate();
        let endDate = moment(value).toDate();
        return endDate > startDate;
      }
    )
    .required("This field is required"),
  validTo: yup
    .string()
    .datetime("Enter valid date")
    .test(
      "dates-test2",
      "Validity end date should be after campaign end date",
      (value, context) => {
        let startDate = moment(context.parent.endDate).toDate();
        let endDate = moment(value).toDate();
        return endDate > startDate;
      }
    )
    .required("This field is required"),
  supplierCode: yup.string().required("This field is required"),
  supplierName: yup.string().required("This field is required"),
  supplierContact: ShortUserSchema,
  zones: yup.array().of(IdNamePairSchema),
  markets: yup.array().of(IdNamePairSchema),
  companyCodes: yup.array().of(IdNamePairSchema),
  incoTerms: yup.string().required("This field is required"),
  mediaFiles: yup
    .array()
    .of(MediaFileSchema)
    .min(1, "At least one image is required"),
});

export const CampaignMaterialSchema = PromoItemRowSchema.shape({
  // we're exending existing schema with new fields.
  id: yup.number().nullable().notRequired(),
  longDescription: yup.string().nullable().notRequired(),
  orderWindowId: yup.number().required("This field is required"),
  minimumOrderQuantity: yup.number().required("This field is required"),
  // packQuantity: yup.number().required("This field is required"),
  // packUnit: yup.string().required("This field is required"),
  purchQuantity: yup.number().required("This field is required"),
  priceRanges: yup.array().of(
    yup.object().shape({
      threshold: yup
        .number()
        .required("This field is required")
        .test(
          "threshold",
          "Threshold should higher or equal to the Minimum Order Quantity (MOQ)",
          (value, context) => {
            console.log({ value, context });
            return value >= context.from[1].value.minimumOrderQuantity;
          }
        ),
      unitPrice: yup.number().required("This field is required"),
    })
  ),
});
